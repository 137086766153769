$ = jQuery
$ ->

  $('#block-system-main-menu').each ->
    wrapper = $(this)
    wrapper.find('> h2').click ->
      wrapper.find('> .content-wrapper .content > ul.menu').slideToggle()
    wrapper.find('li.expanded > .nolink').click ->
      $(this).toggleClass('open').parent().find('> ul').slideToggle()

  carousel = (rootSelector, wrapperSelector, itemSelector) ->
    $(rootSelector).each ->
      $(this).append('<div class="btn prev" >\uf053</div>').append('<div class="btn next">\uf054</div>')


      $(this).find('.btn.next').click ->
        wrapper      = $(rootSelector).find wrapperSelector
        wrapperWidth = wrapper.outerWidth true
        items        = wrapper.find itemSelector
        itemWidth    = items.outerWidth true
        count        = items.length
        minOffset    = -itemWidth*count + wrapperWidth

        offset = Math.max (if wrapper.data 'offset' then wrapper.data 'offset' else 0) - itemWidth, minOffset
        wrapper.css 'transform', 'translateX('+offset+'px)'
        wrapper.data 'offset', offset
        console.log offset
        return false
      $(this).find('.btn.prev').click ->
        wrapper      = $(rootSelector).find wrapperSelector
        items        = wrapper.find itemSelector
        itemWidth    = items.outerWidth true

        offset = Math.min (if wrapper.data 'offset' then wrapper.data 'offset' else 0) + itemWidth, 0
        wrapper.css 'transform', 'translateX('+offset+'px)'
        wrapper.data 'offset', offset
        console.log offset
        return false

  carousel '#block-menu-menu-front-menu', '.content > ul.menu', '> li'
  carousel '#block-views-people-front-block', '.view-content', '> .views-row'

  $('.nolink').attr 'onclick', ''

  windowProxy = $('#win-height')
  if windowProxy.length
    $.fn.checkIsScrolled = ->
      if $(this).length
        if $(this)[0].getBoundingClientRect().bottom >= windowProxy.height()
          $(this).addClass 'scrolled'
        else
          $(this).removeClass 'scrolled'

    $.fn.isOffscreen = ->
      bounds = $(this).offset()
      bounds.bottom = bounds.top + $(this).outerHeight()
      viewport =
        top: $(window).scrollTop()
      viewport.bottom = viewport.top + $(window).height()
      h = 20
      return bounds.bottom + h < viewport.top || bounds.top - h > viewport.bottom

    $.fn.animateMe =  ->
      if $(this).length && !$(this).isOffscreen() && !$(this).hasClass 'animated'
        r = Math.random() * (2 - 1) + 1
        anims = [
          'slide-in-left',
          'slide-in-right',
          'slide-in-up',
          'slide-in-down',
        ]
        for anim in anims
          if $(this).hasClass anim
            $(this).addClass 'animated'
            $(this).css 'animation', anim + ' ' + r + 's'

    animateBlocks = ->
      $('#block-views-voxpops-front-block').addClass('animate').addClass 'slide-in-down'
      $('#block-menu-menu-front-menu .content li').addClass('animate').addClass 'slide-in-down'
      $('#block-views-testimonials-front-block .views-row').addClass('animate').addClass 'slide-in-up'
      $('.region-header .inner').addClass('animate').addClass 'slide-in-down'
      $('#block-nodeblock-1').addClass('animate').addClass 'slide-in-down'
      $('input, textarea').addClass('animate').addClass 'slide-in-right'
      $('.banner').addClass('animate').addClass 'slide-in-up'
      $('.node-type-project .panel-wrapper').addClass('animate').addClass 'slide-in-up'
      $('#block-block-9').addClass('animate').addClass 'slide-in-left'
      $('.animate').each ->
        $(this).animateMe()

    animateBlocks()

    $.fn.getActiveSlidePanel = ->
      return $(this).find('li.flex-active-slide').prev().find('.field-name-field-body')

    $('#block-nodeblock-1 .flexslider-processed').each ->
      if $(this).length
        el = $(this)
        fs = $(this).data('flexslider')
        el.getActiveSlidePanel().css 'opacity', '1'
        fs.vars.before = ->
          el.getActiveSlidePanel().css 'animation-name', 'fade-out'
        fs.vars.after = ->
          el.getActiveSlidePanel().css 'animation-name', 'fade-in'

    selector = '.node-type-project .region-blockgroup-content-wrapper'
    $(selector).checkIsScrolled()
    $(window).on 'scroll', ->
      $(selector).checkIsScrolled()
      animateBlocks()
    .on 'resize', ->
      $(selector).checkIsScrolled()

